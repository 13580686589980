// authentication context

import React, { createContext, useEffect, useMemo, useState } from 'react';
import {
  useAffiliateLookup,
  AffiliateLookupPayload,
} from '@agria/paws/src/hooks/useAffiliateLookup';
import { useCheckBreederEmail } from '@agria/paws/src/hooks/useCheckBreederEmail';
import { UseFormReturn, useForm, UseMutateFunction } from 'react-hook-form';
import { Affiliate } from '@agria/paws/src/types';
import { isBrowser } from '@agria/utils';
import { useDataLayerPush } from '@agria/theme/src/hooks/useDataLayerPush';
import { useAffiliateDetails } from '../hooks/useAffiliateDetails';

interface AuthContextProps {
  id: string;
  loginUser: UseMutateFunction<
    Affiliate,
    unknown,
    AffiliateLookupPayload,
    unknown
  >;
  loginError: boolean;
  loginData: Affiliate | undefined;
  formMethods: UseFormReturn<any>;
  onSubmit: (data: any) => void;
}
interface AuthContextProviderProps {
  children: React.ReactNode;
}

export const AuthContext = createContext({} as AuthContextProps);
AuthContext.displayName = 'AuthContext';

export const AuthProvider = ({ children }: AuthContextProviderProps) => {
  const {
    mutate: loginUser,
    data: loginData,
    isError: loginError,
  } = useAffiliateLookup();
  const {
    mutate: checkUser,
    data: checkData,
    isError: checkError,
  } = useCheckBreederEmail();
  // stored the user ID in state so we can use it later
  // need to sync this id to session storage for page refreshes
  // and then retore it from session storage on page load

  // GA Datalayer
  const { dataLayerPush } = useDataLayerPush();
  const { affiliateType } = useAffiliateDetails();

  const defaultId = isBrowser
    ? window.sessionStorage.getItem('Agria@AffiliateId')
    : '';
  const [id, setId] = useState<string | null>(defaultId);

  const formMethods = useForm({
    mode: 'onBlur',
  });
  const { formState } = formMethods;
  const { errors, isValid } = formState;

  useEffect(() => {
    const newId = loginData?.Id || checkData;
    if (newId) {
      setId(newId);
      window.sessionStorage.setItem('Agria@AffiliateId', newId);
    }
  }, [loginData, checkData]);

  const returnValues = useMemo(() => {
    const onSubmit = (data: any) => {
      if (!isValid) return;
      const payLoad: AffiliateLookupPayload = {};
      let justEmail = true;
      if (data.email) {
        payLoad.email = data.email;
      }
      if (data.agriaId) {
        payLoad.agriaId = data.agriaId;
        justEmail = false;
      }
      if (data.postcode) {
        payLoad.postcode = data.postcode;
        justEmail = false;
      }
      // Regex to see if postcodeOrId is a number, then we need to set agriaId else need to set postcode
      if (data.postcodeOrId) {
        const numberRegEx = /^[0-9]+$/;
        if (numberRegEx.test(data.postcodeOrId as string)) {
          payLoad.agriaId = data.postcodeOrId;
        } else {
          payLoad.postcode = data.postcodeOrId;
        }
        justEmail = false;
      }
      dataLayerPush({
        event: `${affiliateType}_enrolment_journey_interaction`,
        interaction_type: 'stage completed',
        funnel_stage: 'email address',
        agria_id: payLoad?.agriaId ?? '',
      });

      loginUser(payLoad);
    };

    return {
      loginError: loginError || checkError,
      loginUser,
      loginData,
      formMethods,
      onSubmit,
      id,
    };
  }, [loginError, checkError, loginUser, loginData, formMethods, id, isValid]);

  return (
    <AuthContext.Provider value={returnValues}>{children}</AuthContext.Provider>
  );
};
