import { useStaticQuery, graphql } from 'gatsby';

export const useAffiliateDetails = () => {
  const { site } = useStaticQuery(graphql`
    query GetAffiliateMetaLDC {
      site {
        siteMetadata {
          affiliateType
          affiliateSourceId
        }
      }
    }
  `);

  return site.siteMetadata;
};
