export type FormSteps =
  | 'login'
  | 'species'
  | 'petDetails'
  | 'petHistory'
  | 'ownerDetails'
  | 'policyStartDate'
  | 'declaration'
  | 'review'
  | 'confirmation';

export type FormPages =
  | 'login'
  | 'species'
  | 'details'
  | 'owner-details'
  | 'cover'
  | 'review'
  | 'confirmation';

export const indexPage: FormPages = 'login';
export const indexStep: FormSteps = 'login';

export const topLevelSteps = [
  'species',
  'petDetails',
  'activityLevel',
  'policyStartDate',
  'paymentMethod',
  'confirmation',
];

export const stepToPageMap: Record<FormSteps, FormPages> = {
  login: 'login',
  species: 'species',
  petDetails: 'details',
  petHistory: 'details',
  ownerDetails: 'owner-details',
  policyStartDate: 'owner-details',
  declaration: 'cover',
  review: 'review',
  confirmation: 'confirmation',
};
